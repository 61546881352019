/* eslint-disable react/no-danger */
import React from 'react';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';

import { Header, LayoutUI, Stack } from '../../ui-kit';

// import CONTENT from './content';
import * as styles from './Faq.module.scss';

const FAQ = [
  {
    title: '1: Project Happiness: The Basics',
    items: [
      {
        question: '<p>How did Project Happiness Start?</p>',
        answer:
          "<p>Project Happiness began in response to founder Randy Taran’s desire to provide her children with the tools they needed to generate their own lasting happiness. Randy quickly discovered that the issues her children were facing – bullying, depression, stress – were issues not just for her family, but issues that were pandemic throughout her community, the U.S. and the world. After speaking with the experts, she was shocked to hear that the World Health Organization predicts depression to be the number 2 disease for all ages by 2020. She wanted to do something that would speak to not only her children, but to kids everywhere. With a background in film, she decided to start there. The film led to the <a href='https://shop.projecthappiness.org/collections/educators-and-parents' title='The Book'>Project Happiness Handbook</a>, a compilation of the best of positive psychology, mindfulness and neuroscience which <a href='https://shop.projecthappiness.org/collections/educators-and-parents' Title='The Movie'>became the basis for a Movie</a>. <a href='https://shop.projecthappiness.org/collections/educators-and-parents' Title='Educational Curriculum'>Then a team of educators created the educational curriculum</a>, which is now used in throughout the world in over 90 countries.</p>"
      },
      {
        question:
          '<p>How does Project Happiness feel about treating depression with pharmaceuticals?</p>',
        answer:
          '<p>The programs at Project Happiness have been created to bring practical preventative wellness tools and greater awareness into everyday life. Anyone will benefit by practicing ways to generate more happiness and emotional resilience from within. That said, Project Happiness alone is not a prescription for clinical depression; It is an effective tool to be used along with other approaches. In cases of clinical depression, one of these approaches may be pharmaceuticals. Just as insulin is useful for diabetes, medications may be useful to help the body achieve biochemical balance. These medications are necessary to help the body achieve biochemical balance. And just like people who have diabetic tendencies can combat that disease with diet and exercise, Project Happiness can be part of a toolbox to help deal with depression in a proactive and engaging way.</p>'
      },
      {
        question:
          '<p>How does the film factor in to the work Project Happiness does?</p>',
        answer:
          "<p>Whether in a community, small group or school setting, the <a href='https://shop.projecthappiness.org/collections/educators-and-parents/products/project-happiness-dvd' title='Film'>award-winning film is the perfect way</a> to bring people together and activate a positive change. <a href='https://www.projecthappiness.org/get-involved/speaker-series/film-screening-request-form' target='_blank' title='Host a Screening'>Screening the film is also a sure fire way</a> to inspire participants while introducing the programs. Besides, you get to hear the Dalai Lama&#8217;s laughter&#8230;completely contagious.</p>"
      },
      {
        question: '<p>Is Project Happiness a Buddhist organization?</p>',
        answer:
          '<p>No. Project Happiness is not affiliated with any religious institution although we do partner with organizations representing all faiths to help reach as many individuals as possible. The Dalai Lama’s appearance in the Project Happiness film as well as his support of our organization since its inception has been that of a world political and thought leader. His enthusiasm for the neuroscientific underpinnings of mindfulness aligns with the intentions of Project Happiness to provide science-based resources to create greater happiness starting from the inside.</p>'
      },
      {
        question: '<p>What is Social and Emotional Learning (SEL)?</p>',
        answer:
          "<p>Social and emotional learning (SEL) is the process through which we learn to recognize and manage emotions, care about others, make good decisions, behave ethically and responsibly, develop positive relationships, and avoid negative behaviors. <a href='http://www.projecthappiness.org/social-and-emotional-learning/' title='Social and Emotional Learning'>Learn more about SEL here</a>.</p>"
      },
      {
        question:
          '<p>Where does the money from Project Happiness come from?</p>',
        answer:
          '<p>Project Happiness is a non-profit organization that relies on donations and grants to run its operation. Project Happiness is fortunate to have some generous individual donors who have funded the operations so far. We also receive support from grants, organizations and individuals who are enthusiastic about we are doing and are committed to spreading Happiness.</p>'
      },
      {
        question:
          '<p>Who developed Project Happiness’ educational programs?</p>',
        answer:
          "<p>​Our educational programs have been developed by a team of educators, consultants, and technologists​, who are experts in the area of <a href='http://www.projecthappiness.org/faq/what-is-social-and-emotional-learning-sel-2/' target='_blank' title='What is Social and Emotional Learning (SEL)?'>social-emotional learning</a>.</p>"
      }
    ]
  },
  {
    title: '2: Project Happiness: Education',
    items: [
      {
        question:
          '<p>As a parent, how can I get Project Happiness into my child’s school?</p>',
        answer:
          "<p>Do you know a teacher or principal (or PTA/PTSA/PTO) who cares about the emotional life of students? Talk with them about getting Project Happiness into your school or afterschool programs. The <a href='https://www.projecthappiness.org/science-of-happiness' title='Educational Resources'>Educational Resources page has free resources</a> and all the introductory materials you need to bring with you. Just make the introduction (to teacher, principal, counselor) and we’d be happy to take it from there. We’re just a phone call or email away at <a href='mailto:info@projecthappiness.org' target='_blank'>info@projecthappiness.org</a>. Planning a school-wide <a href='https://www.projecthappiness.org/get-involved/speaker-series/film-screening-request-form' target='_blank' title='Host a Screening'>film screening</a> is another excellent way to raise awareness and get everyone on board.</p>"
      },
      {
        question:
          '<p>Can we get someone from Project Happiness to come to our school?</p>',
        answer:
          "<p><a href='mailto:holly@projecthappiness.org' target='_blank'>Click here</a> if you would like to have a representative from Project Happiness visit your school.</p>"
      },
      {
        question: '<p>Do you ever do teacher trainings?</p>',
        answer:
          "<p>Please <a href='mailto:info@projecthappiness.org' target='_blank'>contact our office</a> if you would like to look into a training for your school or district.</p>"
      },
      {
        question:
          '<p>Do you have anything for kids to do on their own? Like a happiness group or club?</p>',
        answer:
          "<p>Yes! <a href='https://shop.projecthappiness.org/blogs/project-happiness/lg-sponsored-project-happiness-clubs-apply-today' title='Educational Resources'>Click here</a> for information on our Project Happiness Student Clubs. <a href='https://circles.projecthappiness.org/' Title='Happiness Club'/>Click here</a> to register and you will be directed.</p>"
      },
      {
        question:
          '<p>I have so much to cover already in each class period, and really want to include Project Happiness, too. How do other teachers do this?</p>',
        answer:
          '<p>We understand that classroom time is valuable, and Project Happiness helps students’ capacity to learn by improving focus, productivity and emotional management skills. “The brain at positive is 31% more productive than at negative, neutral or stressed.”</p><p>For this reason schools have been electing to create class time for these fundamental life skills or offering them in 15- minute segments during the school day or after school.</p><p>Another option is to infuse the themes in Project Happiness within specific areas, such as letter writing (write a gratitude letter), art projects (illustrating the inner positive friend mindset and the inner meanie mindset), media center research projects (what have I learned I can do about bullying), etc. You will notice the difference!</p>'
      },
      {
        question:
          '<p>I teach 2nd grade–is that age too young for your programs?</p>',
        answer:
          '<p>Absolutely not. The program for elementary school-aged children is geared for younger students as well. There are a wide variety of supplemental activities and you can choose the ones that suit the developmental age of your children.</p>'
      },
      {
        question:
          '<p>I would like to incorporate Project Happiness into my coaching work, is that possible?</p>',
        answer:
          "<p>The Project Happiness materials incorporate the best of positive psychology, mindfulness, neuroscience as well as the latest from the science of happiness. The curricula are excellent resources full of activities and reflections that you can use directly with your clients (see <a href='http://www.projecthappiness.org/educational-resources/' target='_blank' title='Educational Resources'>Educational Resources page</a>).</p>"
      },
      {
        question: '<p>Is your curriculum available in other languages?</p>',
        answer:
          '<p>Currently the program “Circles of Happiness” is available in Spanish, and the film is available with Spanish subtitles.</p>'
      },
      {
        question:
          '<p>We plan to use Project Happiness during our advisory periods, but only have 20 minutes a day. Do you have any suggestions?</p>',
        answer:
          "<p>The Elementary and Middle School Curricula (see <a href='http://www.projecthappiness.org/educational-resources/' target='_blank' title='Educational Resources'>Educational Resources</a> page) can be divided into 15-20 minute activities, perfect for an Advisory period. The <a href='http://www.projecthappiness.org/about-the-book/' target='_blank' title='About the Book'>Project Happiness Handbook</a> also features thought-provoking exercises that can be done within that time frame.</p>"
      },
      {
        question: '<p>Why isn’t there something like this for adults?</p>',
        answer:
          "<p>For adults we have <a href='http://www.projecthappiness.org/happiness-habits/' target='_blank' title='Happiness Habits'>Happiness Habits</a>. See also, <a href='http://www.projecthappiness.org/happiness-circles/' target='_blank' title='Happiness Circles'>Circles of Happiness.</a></p>"
      }
    ]
  },
  {
    title: '3: Project Happiness: The Film',
    items: [
      {
        question:
          '<p>Can I show the DVD in my classroom without paying a screening fee?</p>',
        answer:
          "<p>​You can <a href='http://www.projecthappiness.org/screening-request-form/' target='_blank' title='Screening Request Form'>request a screening</a> for the film using the form on our website. There is a screening fee​ based on the audience size. If securing funds is an issue, we do offer some scholarships to screen the film at no fee.</p>"
      },
      {
        question: '<p>Do you have any materials to go along with the film?</p>',
        answer:
          "<p>Please click on the press page to download posters, logos etc. For though-provoking questions for Q and A after the screening, <a href='http://www.projecthappiness.org/host-a-screening/' target='_blank'>click here</a>. (PLEASE link to a download of Film Q &#038; A – Audience Questions for after the PH Film – see attached)</p>"
      },
      {
        question:
          '<p>How can I convince my principal that your film doesn’t include any religious messages?</p>',
        answer:
          '<p>Sometimes people express concerns about the film having religious overtones because of the involvement of the Dalai Lama. The Dalai Lama is just one of several luminaries who were interviewed for the film. His involvement was after his best-selling novel, Ethics for the New Millennium had just been released and he discussed ethics from the perspective of a world leader. He was invited to be involved as Mrs. Taran felt that his message reached across all sorts of boundaries.</p>'
      },
      {
        question:
          '<p>I saw the film, and wonder how the story of privileged kids in California who travel to India correlates to our inner-city kids dealing with poverty, gangs, addiction, teenage pregnancies, and juvenile hall?</p>',
        answer:
          '<p>While it’s true that everyone in the world is dealing with different situations–some more challenging and difficult than others–it’s also true that we share the same basic condition: the human condition. The students from California, living in a first-world country with freedom, opportunity, no civil war, etc. discovered that these things in the external world did not guarantee happiness inside. The Project Happiness programs offer the skills and tools to strengthen our inner world and take charge of our own happiness that are relevant to everyone.</p><p>This quote from California student Emily explains it well:<br>“I learned that you really can’t choose the situations you’re put in, but you can choose how you deal with them. And the people in the world who are happy are the people who are dealing with their situations well…and the people who are unhappy are the people who aren’t.”</p>'
      },
      {
        question:
          '<p>Is the film available in other languages besides English?</p>',
        answer:
          "<p>Currently, the Elementary School curriculum is available in Spanish, as is our free “Circles of Happiness” program. The <a href='http://www.projecthappiness.org/purchase-the-film/' target='_blank' title='Purchase the Film'>film</a> is also available with Spanish subtitles.</p>"
      }
    ]
  },
  {
    title: '4: Project Happiness: The Book',
    items: [
      {
        question: '<p>Do you offer bulk proces?</p>',
        answer:
          "<p>Yes, <a href='http://www.projecthappiness.org/purchase-the-book/' target='_blank' title='Purchase the Book'>Click here</a> for information on bulk discounts for the book and the film.</p>"
      },
      {
        question: '<p>How can I use the book in my classroom (8th grade)?</p>',
        answer:
          '<p>The book can be used along with curricula for younger grades or as a stand-alone when working with older kids. It is packed with engaging, reflective, and experiential activities, and is meant to be used as a handbook that the participant will refer to long after the lessons are done. Many counselors use this as a resource for one on one sessions as well.</p>'
      },
      {
        question:
          '<p>The book has great exercises in it–how was it put together?</p>',
        answer:
          '<p>The information and exercises from the book draw from he best of positive psychology, mindfulness and neuroscience and make this accessible in a creative and engaging style. A wide range of thought-leaders from the field of the science of happiness were included to provide a comprehensive foundation for building emotional resilience, self-awareness, and to activate the happiness within.</p>'
      },
      {
        question: '<p>Where did the information come from?</p>',
        answer:
          '<p>The information and exercises from the book draw from he best of positive psychology, mindfulness and neuroscience and make this accessible in a creative and engaging style. A wide range of thought-leaders from the field of the science of happiness were included to provide a comprehensive foundation for building emotional resilience, self-awareness, and to activate the happiness within.</p>'
      }
    ]
  },
  {
    title: '5: Project Happiness: Get Involved',
    items: [
      {
        question:
          '<p>Can I get a job or internship with Project Happiness?</p>',
        answer:
          "<p>Check out our employment opportunity page to learn more about <a href='http://www.projecthappiness.org/jobs-internships/' title='Jobs &#038; Internships'>current openings at Project Happiness</a>.</p>"
      },
      {
        question: '<p>Can I volunteer for Project Happiness?</p>',
        answer:
          "<p>Yes. We are always looking for volunteers. You can email us at <a href='mailto:hello@projecthappiness.org' target='_blank'>hello@projecthappiness.org</a> here.</p>"
      },
      {
        question:
          '<p>How can my organization partner with Project Happiness?</p>',
        answer:
          "<p>Project Happiness deeply believes in the power of partnership. If you represent complementary services and programs to our mission, please contact us to be part of our Coalition. Our goal is to create the largest impact possible, in strong cooperation with others. <a href='http://www.projecthappiness.org/happiness-coalition/' target='_blank'>Click here</a> for a list of some our affiliates, supporters and strategic alliance partners. Contact our office to inquire about partnership opportunities.</p>"
      },
      {
        question:
          '<p>How do I start my own Happiness Circle? Do I have to register with you?</p>',
        answer:
          "<p><a href='http://www.projecthappiness.org/happiness-circles/' target='_blank' title='Happiness Circles'>Click here</a> to register and you will be directed to the page that provides everything you need to get started.</p>"
      },
      {
        question:
          '<p>I have many years of experience teaching and writing curriculum. Do you need help creating new programs?</p>',
        answer:
          "<p>Please fill out our <a href='http://www.projecthappiness.org/volunteer/' target='_blank'>volunteer form</a>, noting your experience and we will contact you when we are developing new materials.</p>"
      },
      {
        question:
          '<p>I’d like to help you translate into my native language. How can I help?</p>',
        answer:
          "<p><a href='mailto:seema@projecthappiness.org' target='_blank'>Contact Seema</a> if you would like to help us translate the film translated into another language. We rely on the funds of our generous donors and the time of native-speaking volunteers to make translations possible.</p>"
      },
      {
        question: '<p>What are other ways I can get involved?</p>',
        answer:
          "<p>We recommend spreading the word through <a href='https://www.facebook.com/projecthappiness' target='_blank'>social media</a>, <a href='http://www.projecthappiness.org/happiness-circles/' target='_blank' title='Happiness Circles'>joining a circle</a>, introducing <a href='http://www.projecthappiness.org/educational-resources/' target='_blank' title='Educational Resources'>curriculum to your school</a> or <a href='http://www.projecthappiness.org/donate/' target='_blank' title='Donate'>donating</a> to help us sponsor the many teachers who are waiting to start the programs.</p><p>You can also request a <a href='http://www.projecthappiness.org/host-a-screening/' target='_blank' title='Host a Screening'>film screening</a> at your school, yoga studio, library, church, community center, business, etc. to spread the message.</p>"
      }
    ]
  },
  {
    title: '6: Project Happiness: Donate',
    items: [
      {
        question: '<p>Can I make a donation in memory of someone?</p>',
        answer:
          "<p>Yes. Please email us at <a href='mailto:info@projecthappiness.org'>info@projecthappiness.org</a>. We will be happy to help.</p>"
      },
      {
        question: '<p>Can I make a recurring credit card donation?</p>',
        answer:
          "<p>Recurring gifts are a wonderful way to support the organization’s vital work. Please see our <a href='http://www.projecthappiness.org/donate/' title='Donate'>Donate page</a>, contact us via email <a href='mailto:info@projecthappiness.org' target='_blank'>info@projecthappiness.org</a> and we will assist you.</p>"
      },
      {
        question: '<p>Does Project Happiness issue tax receipts?</p>',
        answer:
          '<p>Yes, Project Happiness is a 501(c)3 nonprofit, tax-exempt organization designated by the Internal Revenue Code. Our tax ID number is 26-0162963. When you contribute over $20 to Project Happiness, you will receive via email a charitable tax receipt for income tax purposes. For all other donations, your canceled check or credit card statement can serve as record of your donation.</p><p>If you are making a recurring donation through your credit card, your contribution will be charged automatically on the day and the frequency you have selected. Your credit card statements serve as additional record of your contribution.</p>'
      },
      {
        question:
          '<p>Does Project Happiness sell, rent, or trade donor information?</p>',
        answer:
          '<p>The privacy of our donors is very important to us and we do not share or sell this information.</p>'
      },
      {
        question: '<p>How do I contact Project Happiness about a donation?</p>',
        answer:
          "<p>Thank you for your generosity in supporting Project Happiness. If you have questions about how to help email us at <a href='mailto:hello@projecthappiness.org'>hello@projecthappiness.org</a>.</p>"
      },
      {
        question: '<p>I can’t find my receipt. Can I have another one?</p>',
        answer:
          "<p>We can send you a copy of your receipt. Please email <a href='mailto:hello@projecthappiness.org'>hello@projecthappiness.org</a> with your full name as it appeared on your donation, your address, the date you made the donation and the amount. Please include a phone number or email address so we can get in touch with you if we need to.</p>"
      },
      {
        question:
          '<p>I donated several weeks ago, why haven’t I received a receipt?</p>',
        answer:
          "<p>This is not normally the case, please email <a href='mailto:hello@projecthappiness.org'>hello@projecthappiness.org</a> with as much information as possible about your donation. It’s possible we had an incorrect email or mailing address for you, so when you call or write, tell us your full name as it appeared on your donation, your address, the date you made the donation and the amount. It is also helpful if we know how you made the donation: check, credit card, online, etc. And please include your phone number or email address so we can respond as quickly as possible.</p>"
      },
      {
        question:
          '<p>I want to make a credit card gift but I don’t like submitting the information over the Web.</p>',
        answer:
          "<p>You can also email us at <a href='mailto:hello@projecthappiness.org'>hello@projecthappiness.org</a> and we’ll take your credit card information over the phone. Or, you can mail your check to us – Project Happiness, 3130 Alpine Rd., Suite 288-221, Menlo Park CA 94028.</p>"
      },
      {
        question: '<p>Is my credit card information secure?</p>',
        answer:
          '<p>Project Happiness takes every precaution to protect our donors’ information. When you submit sensitive information via the Website, your information is protected both online and offline. We use the industry standard security protocol to communicate with your browser software, which makes it extremely difficult for anyone else to intercept the credit card information you send to us. Independent audits are also conducted on our practices to ensure the privacy, security and appropriate processing of your information on our site. In addition, we work to accurately process your contribution information.</p>'
      },
      {
        question: '<p>My donation didn’t go through. Why?</p>',
        answer:
          "<p>We use PayPal to process our payments so that your credit card information is secure. However, sometimes PayPal rejects payments for unspecified reasons.</p><p>If you received an error message after completing your transaction, it is likely that the information provided, such as the credit card identification number, did not match exactly with what is on file at your bank. For further assistance, or email <a href='mailto:hello@projecthappiness.org' target='_blank'>hello@projecthappiness.org</a>.</p>"
      }
    ]
  }
];

const FAQComponent = () => (
  <Layout>
    <Navbar />
    <MobileNavbar />
    <LayoutUI narrow>
      <div className={styles.FaqLayout}>
        <div className={styles.FaqHeader}>
          <Stack alignment="center" distribution="center">
            <Header>FAQs</Header>
          </Stack>
        </div>
        <div className={styles.Sections}>
          {FAQ.map(({ title, items }) => (
            <div className={styles.Section} key={title}>
              <div className={styles.SectionHeader}>
                <Header size="small">{title}</Header>
              </div>
              <div className={styles.Items}>
                {items.map(({ question, answer }) => (
                  <div className={styles.Item} key={question}>
                    <div
                      className={styles.Question}
                      dangerouslySetInnerHTML={{ __html: question }}
                    />
                    <div
                      className={styles.Answer}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </LayoutUI>
  </Layout>
);

export default FAQComponent;
